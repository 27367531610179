<template>
  <v-container>
    <v-row>
      <v-col sm="6">
        開始時間
        <vue-timepicker v-model="start" hide-disabled-hours close-on-complete :hour-range="[11,12,13,14,15,16,17,18,19,20,21,22]" @input="changeTime"></vue-timepicker>
      </v-col>
      <v-col sm="6">
        終了時間
        <vue-timepicker v-model="end" hide-disabled-hours close-on-complete :hour-range="end_range" @input="changeTime"></vue-timepicker>
      </v-col>
      <v-col cols="12" sm="4">
        時間：{{ time }}
      </v-col>
      <v-col cols="12" sm="4">
        通常：{{ price }}円
      </v-col>
      <v-col cols="12" sm="4">
        学割：{{ gakuwari }}円
      </v-col>
    </v-row>
  </v-container> 
</template>

<script>

export default {
  data: () => ({
    start: '',
    end: '',
    time: '',
    price: '',
    gakuwari: '',
    end_range: [11,12,13,14,15,16,17,18,19,20,21,22],
  }),
  methods: {
    changeTime: function() {

      const prices = [
        [30, 330],
        [40, 430],
        [50, 530],
        [60, 630],
        [70, 800],
        [80, 900],
        [90, 1030],
        [120, 1100],
        [130, 1200],
        [140, 1300],
        [150, 1400],
        [170, 1500],
        [180, 1600],
        [190, 1700],
        [200, 1800],
        [210, 1900],
        [9999, 2000],
      ];

      const prices2 = [
        [30, 330],
        [40, 430],
        [50, 530],
        [90, 550],
        [100, 700],
        [110, 750],
        [120, 800],
        [130, 850],
        [140, 900],
        [150, 950],
        [160, 1000],
        [170, 1050],
        [180, 1100],
        [190, 1200],
        [200, 1300],
        [210, 1400],
        [9999, 1500],
      ];

      const timeformat = /^\d\d:\d\d$/;
      if(this.start.match(timeformat) !== null && this.end.match(timeformat) !== null) {
        var h, m;
        let diff = (new Date('1970/1/1 '+ this.end + ':00') - new Date('1970/1/1 '+ this.start + ':00')) / 60000;
        if(diff > 0) {
          h = Math.floor(diff/60);
          m = diff - h * 60;
          this.time = h + '時間' + m + '分';
          this.price = this._calcPrice(diff, prices);
          this.gakuwari = this._calcPrice(diff, prices2);
        } else {
          this.time = ''
          this.price = ''
          this.gakuwari = ''
        }
      }
    },
    _calcPrice: function(min, prices) {
      return prices.find(p => min <= p[0])[1];
    }
  },
};
</script>
