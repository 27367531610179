import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';

import VueTimepicker from 'vue2-timepicker';
import 'vue2-timepicker/dist/VueTimepicker.css'
Vue.component("vue-timepicker", VueTimepicker);
Vue.config.productionTip = false

new Vue({
  vuetify,
  render: h => h(App)
}).$mount('#app')
